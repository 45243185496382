import React, {Component} from 'react';
import {Container, Card, CardBody, CardHeader, Col, Row, Button,} from 'reactstrap';
import Modal from "../components/utility/Modal"
import ComponentStatus, {ComponentStateValue} from "../model/ComponentStatus"
import {strings} from "../resources/strings";
import GatewayService from "../remote/services/GatewayService";
import {find, get, head, last, partition, sortBy, sum, zip} from 'lodash'
import Loader from "../utility/Loader";
import CongressesService from "../remote/services/CongressesService";
import {ResultRow} from "./Results/ResultRow";
import {OccurrenceClinicCaseQuestion} from "../remote/types/backend/occurrenceClinicCaseQuestion.types";
import background_tenda from '../assets/results/tenda_sezione_gioco.png';
import ResultsTable from "./Results/ResultsTable";
import logoCentrale from '../assets/results/Logo_centrale.png';
import ResultsBar from "./Results/ResultsBar";

import coccarda_a from '../assets/results/Coccarda_A.png';
import coccarda_b from '../assets/results/Coccarda_B.png';
import coccarda_c from '../assets/results/Coccarda_C.png';
import coccarda_d from '../assets/results/Coccarda_D.png';
import {AnswerKeys, TableColorStatus, TableKeys} from "./Results/Results.types";
import {computePercentages, matchResponse} from "./Results/utils";
import ModalError from "./utility/ModalError";
import {extractCodeFromError} from "../utility/common";


const maximumLengths = [
  { val: 0, minPct: 0.03 },
  { val: 10, minPct: 0.04 },
  // { val: 100, minPct: 0.05 },
];

const computeLength = (pct: number, value: number) => Math.max(pct, Math.max(...maximumLengths.filter(x => x.val <= value).map(x => x.minPct)));

const NESSUNA_RISPOSTA_STRING = "nessuna risposta";
const RISPOSTA_VUOTA_STRING = " ";

const REFRESH_RATE_MS = 3000;

interface IResultsLiveProps {
  match: {
    params: {
      congressId: string;
      clinicCaseCode: string;
    }
  }
}

type TableStatus = {mode: "PENDING" | "ANSWERED", answer?: AnswerKeys};

interface IResultsLiveState {
  showModal: boolean;
  showLoader?: boolean;
  componentStatus: ComponentStateValue;
  errorMessages: string[] | string;
  clinicCaseStats: any;
  questionText?: string;
  resultState?:
    "NO_QUESTION" // caso 402, vedi doc
    | "SOTTOMESSA" // result[0].status
    | "VISUALIZZAZIONE_RISULTATI" // result[0].status
  ;
  tables: {[k in TableKeys]: TableStatus};
  responseText?: string;
  responseEnum?: AnswerKeys;
  responseImage?: string;
  responses: string[];
}
interface ITableConfig {
  tableEnum: TableKeys;
  username: string;
}

interface IResponseConfig {
  answerEnum: AnswerKeys;
  text: string;
  isCorrect: boolean;
}

const allTables: ITableConfig[] = [
  {tableEnum: 1, username: 'teamuno@cnc.events'},
  {tableEnum: 2, username: 'teamdue@cnc.events'},
  {tableEnum: 3, username: 'teamtre@cnc.events'},
  {tableEnum: 4, username: 'teamquattro@cnc.events'},
  {tableEnum: 5, username: 'teamcinque@cnc.events'},
  {tableEnum: 6, username: 'teamsei@cnc.events'},
]

const loremIpsum = "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commod consequat. Duis autem vel eum iriure dolor in";

const initialTableStatus: {[k in TableKeys]: TableStatus} = {
    1: {mode: "PENDING"},
    2: {mode: "PENDING"},
    3: {mode: "PENDING"},
    4: {mode: "PENDING"},
    5: {mode: "PENDING"},
    6: {mode: "PENDING"},
  }

const initialState: Pick<IResultsLiveState, "tables" | "resultState" | "questionText" | "responses"> = {
  tables: initialTableStatus,
  resultState: "NO_QUESTION",
  questionText: undefined,
  responses: [],
}
const emptyResults: {[k in AnswerKeys]: number} = {
  A: 0,
  B: 0,
  C: 0,
  D: 0,
}

class ResultsLive extends Component<IResultsLiveProps, IResultsLiveState> {
  congressesService: CongressesService;
  refreshDataInterval: any;
  refreshDataCache: Promise<void> | undefined;

  /**
   * COMPONENT LIFECYCLE METHODS
   */

  constructor(props: IResultsLiveProps){
    super(props);
    this.state = {
      showModal:false,
      componentStatus:ComponentStatus.LOADING,
      errorMessages:[],
      clinicCaseStats: undefined,
      ...initialState,
    }
    this.congressesService = new CongressesService();
  }

  getCongressId = () => {
    return this.props.match.params.congressId;
  }

  getClinicCaseCode = () => {
    return this.props.match.params.clinicCaseCode;
  }

  componentDidMount() {
    this.getData()
  }

  componentWillUnmount() {
    try {
      clearInterval(this.refreshDataInterval);
    } catch (e) {
    }
  }

  showLoader = (show: boolean) => {
    this.setState({showLoader:show})
  }

  /**
   * REMOTE REQUESTS
   */

  _getData = (): Promise<Partial<IResultsLiveState>> => {
    return this.congressesService.getStatsByIdAndUserAndClinicCaseCode(this.getCongressId(), this.getClinicCaseCode())
      .then(res => {
        const stats = res.data;
        if (Array.isArray(stats.results) && stats.results.length > 0){
          const data = stats;
          const status = data.status;
          const risposteValide = data.risposte.map(x => x.testoRisposta)
          const rispostaEsatta = find(data.risposte, {'rispostaEsatta':true});
          if(rispostaEsatta==undefined)
            return Promise.reject(new Error("Nessuna risposta esatta trovata"))

          const correctAnswer = matchResponse(rispostaEsatta.testoRisposta)
          if (correctAnswer === null) {
            return Promise.reject(new Error("Cannot parse response"));
          }
          const responseText = rispostaEsatta.testoRisposta.substring(3);
          if (status !== "SOTTOMESSA" && status !== "VISUALIZZAZIONE_RISULTATI") {
            return Promise.reject(new Error(`Stato non gestito: ${data}`))
          }
          const tableStatusEntries: [TableKeys, TableStatus][] = allTables
            .map(({tableEnum, username}) => {
              const userResult = find(data.userResults, x => x.username === username);
              if (userResult && Array.isArray(userResult.response) && userResult.response.length>0 && risposteValide.includes(userResult.response[0])){
                const answer = matchResponse(userResult.response[0]);
                if (answer !== null) {
                  return [tableEnum, {mode: "ANSWERED", answer}];
                }
              }
              return [tableEnum, {mode: "PENDING"}]
            });
          return {
            tables: Object.assign({}, ...tableStatusEntries.map(([k, v]) => ({[k]: v}))),
            resultState: status,
            questionText: data.question,
            responseText,
            responseEnum: correctAnswer,
            responses: sortBy(risposteValide, x => head(x)).map(x => {
              if (last(x) === "_") {
                return x.substring(0, x.length - 1);
              } else {
                return x
              }
            })
          }
        } else {
          return Promise.reject(new Error("Risposta vuota"))
        }
      })
      .catch(e => {
        const code = extractCodeFromError(e);
        console.log(`code: ${code}`)
        if (code === 402) {
          return {
            ...initialState,
            resultState: "NO_QUESTION",
          };
        } else {
          console.error(e);
          return Promise.reject(e);
        }
      })
  }

  updateData = () => {
    if (!this.refreshDataCache) {
      this.refreshDataCache =
        this._getData()
          .then(state => this.setState(state as IResultsLiveState))
          .finally(() => this.refreshDataCache = undefined);
    }
  }

  getData(){
    this.showLoader(true);
    return this._getData()
      .then(state => this.setState(state as IResultsLiveState))
      .then(() => {
        this.refreshDataInterval = setInterval(() => this.updateData(), REFRESH_RATE_MS);
      })
      .catch(() => {
        this.setState({
          errorMessages: "Si è verificato un errore. Si prega di chiudere e riaprire la pagina",
          componentStatus: ComponentStatus.ERROR
        })
      })
      .finally(() => {
        this.showLoader(false)
      })
/*    return this.gatewayService.getGateways().then(response => {
      console.log(`Users - response: ${JSON.stringify(response.data)}`)
      let gateways = response.data.result.map((cur)=>{delete(cur.roles); delete(cur.hash); return cur})
      this.showLoader(false);
      this.setState({originalGateways: cloneDeep(gateways), gateways: gateways, componentStatus:ComponentStatus.LOADED
      })
    }).catch(err =>{
      console.log(`Users - err: ${err}`)
      this.showLoader(false);
      this.setState({originalGateways:[], gateways: [], componentStatus:ComponentStatus.ERROR,
        errorMessages: [...this.state.errorMessages, strings.gateway.errors.loading]})
    })*/

  }

  /**
   *
   * @param {OccurrenceClinicCaseQuestion} clinicCaseQuestionStats
   * @returns {JSX.Element}
   */
  renderClinicCaseResults = (clinicCaseQuestionStats: OccurrenceClinicCaseQuestion) => {
    const results = get(clinicCaseQuestionStats, "results", []);
    const [corrects, rest] = partition(results, x => x.isCorrect);

    /**
     * @type {ClinicCaseQuestionResult[]}
     */
    let resultsToShow = [...corrects, ...rest].filter(x => !!x
      && x.response.toLowerCase() !== NESSUNA_RISPOSTA_STRING
      && x.response !== RISPOSTA_VUOTA_STRING);

    const maxCount = Math.max(...resultsToShow.map(x => x.count));
    const total = sum(resultsToShow.map(x => x.count));

    let meanToShow: string | number | undefined = get(clinicCaseQuestionStats, "mean", undefined)
    if(meanToShow!=undefined){
      meanToShow=meanToShow.toFixed(2)
    }

    return (
      <React.Fragment>

        {
          meanToShow!=undefined &&
          <Card className="mean-card">
            <CardHeader className="results-mean-card-header"  style={{display:"flex", justifyContent:"center"}}>
            <span style={{color:"#001B62"}}>
              Punteggio medio:&nbsp;{meanToShow}
            </span>
            </CardHeader>
          </Card>

        }
        <Card>
          <CardHeader className="results-question-card-header"  style={{display:"flex"}}>
            <span>
              {clinicCaseQuestionStats.question}
            </span>
          </CardHeader>
          <CardBody className="results-question-card-body">
            {resultsToShow.length > 0 &&
            zip(resultsToShow, computePercentages(resultsToShow.map(x => x.count))).map(([r, pct], i) =>
                // <span>{JSON.stringify({pct: r.count, text:r.response, progressText: r.percentage})}</span>
              {
                return [
                  <ResultRow
                    backgroundColor={"unset"}
                    pct={total === 0 ? " 0" : pct}
                    text={r && r.response}
                    progressText={`${pct}%`}
                    progressClassName={total === 0 ? "empty-progress" : undefined}
                  />,
                  i === resultsToShow.length - 1 ? undefined : <hr />
                ].filter(x => !!x);
              }
            )
            }
          </CardBody>
        </Card>

        Numero votanti: {sum(resultsToShow.map(x => x.count))}
      </React.Fragment>
    )
  }

  getTeamTable(k: TableKeys): JSX.Element {
    let mode: TableColorStatus;
    if (this.state.resultState === "VISUALIZZAZIONE_RISULTATI") {
      mode = this.state.tables[k].answer || "WHITE";
    } else {
      mode = this.state.tables[k].answer ? "GREY" : "WHITE";
    }
    return <ResultsTable
      mode={mode}
      text={`TEAM ${k}`}
      containerProps={{}}
    />
  }

  getResults = (): {[k in AnswerKeys]: number} => {
    const resultEntries: [AnswerKeys, number][] = (["A", "B", "C", "D"] as AnswerKeys[]).map(k => {
      return [k, Object.values(this.state.tables).filter(x => x.answer && x.answer === k).length];
    })
    return Object.assign({}, ...resultEntries.map(([k, v]) => ({[k]: v})));
  }

  getResponseImage = () => {
    switch (this.state.responseEnum) {
      case "A":
        return coccarda_a;
      case "B":
        return coccarda_b;
      case "C":
        return coccarda_c;
      case "D":
        return coccarda_d;
    }
  }

  canShowResponseBlock = (): boolean => {
    return last(this.state.responseText) !== "_";
  }

  renderCoccarda = () => {
    return (
      <div
        className="d-flex"
        style={{
          marginTop: "15px",
          marginBottom: "15px",
          maxHeight: "195px",
          visibility: this.canShowResponseBlock() ? undefined : "hidden"
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{marginLeft: "auto", marginRight: "auto"}}
        >
          <p
            style={{
              fontSize: "22pt",
              padding: "0 4px",
              lineHeight: "normal",
              fontWeight: 500,
              overflow: "hidden",
              marginRight: "20px",
            }}
          >
            La risposta corretta è
          </p>
          <img
            style={{maxHeight: "98px"}}
            className="align-self-center"
            src={this.getResponseImage()} alt={"Coccarda"} />
        </div>
        {/* <div className="d-flex flex-column" style={{width: "78%"}}>
                <h2
                  style={{fontSize: "33pt", marginTop: "-5px", marginLeft: "5px"}}
                >
                  RISPOSTA
                </h2>
                <p
                  style={{
                    fontSize: "13pt",
                    marginTop: "-4px",
                    padding: "0 4px",
                    lineHeight: "normal",
                    fontWeight: 500,
                    overflow: "hidden",
                  }}
                >
                  {this.state.responseText}
                </p>
              </div> */}
      </div>
    )
  }

  renderEsito = () => {
    return (
      <div
        className="d-flex flex-column"
      >
        <ResultsBar
          results={this.getResults()}
        />
        <h3
          style={{marginLeft: "auto", marginRight: "auto", marginTop: "15px"}}
        >
          Distribuzione percentuale
        </h3>
      </div>
    )
  }

  /**
   * RENDER
   */

  render() {
    return (
      <div
        style={{
          position: "absolute",
          width: "1920px",
          height: "1080px",
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
      <div
        className="d-flex"
        style={{
          position: "absolute",
          width: "1920px",
          height: "1080px",
          backgroundImage: `url(${background_tenda})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: "center top",
          paddingLeft: "94px",
          paddingRight: "94px",
          paddingTop: "147px",
        }}
      >
        {/* Tavoli con position absolute */}
        {this.state.resultState !== "NO_QUESTION" &&
        <>
          <div style={{position: "absolute", left: "130px", top: "236px"}}>
            {this.getTeamTable(1)}
          </div>
          <div style={{position: "absolute", left: "1464px", top: "236px"}}>
            {this.getTeamTable(2)}
          </div>
          <div style={{position: "absolute", left: "130px", top: "530px"}}>
            {this.getTeamTable(3)}
          </div>
          <div style={{position: "absolute", left: "1464px", top: "530px"}}>
            {this.getTeamTable(4)}
          </div>
          <div style={{position: "absolute", left: "130px", top: "823px"}}>
            {this.getTeamTable(5)}
          </div>
          <div style={{position: "absolute", left: "1464px", top: "823px"}}>
            {this.getTeamTable(6)}
          </div>
        </>}
        {/* Colonna centrale */}
        <div
          className="d-flex flex-column flex-grow"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            height: "100%",
            width: "938px",
            paddingLeft: "41px",
            paddingRight: "41px",
            paddingTop: "41px",
          }}
        >
          {/* Logo */}
          {/* <img
            className="align-self-center"
            src={logoCentrale}
            alt={"Logo"}
          /> */}
          {this.state.resultState === "NO_QUESTION" &&
          <h2
            className="align-self-center"
            style={{fontSize: "36pt", marginTop: "30%"}}
          >
            NESSUNA DOMANDA IN CORSO
          </h2>}
          { this.state.resultState !== "NO_QUESTION" &&
            <div
              className="d-flex flex-column"
              style={{marginTop: "20px"}}
            >
              <p
                className="align-self-center text-center"
                style={{
                  fontSize: "26pt",
                  padding: "0 4px",
                  lineHeight: "normal",
                  fontWeight: 500,
                }}
              >
                {this.state.questionText}
              </p>
              {this.state.responses.map((text, i) =>
                <p
                  className=""
                  style={{
                    fontSize: "22pt",
                    padding: "0 8px 8px 8px",
                    lineHeight: "normal",
                    fontWeight: 500,
                    overflow: "hidden",
                    marginTop: i === 0 ? "20px" : undefined,
                    border: "2px solid #ededed",
                    borderRadius: "10px",
                    marginBottom: "12px",
                  }}
                >
                  {text}
                </p>
              )}
            </div>
          }
          { this.state.resultState === "VISUALIZZAZIONE_RISULTATI" &&
          <div
            style={{alignSelf: "center", width: "98%", marginBottom: "9px", marginTop: "auto"}}
          >
            {this.renderCoccarda()}
            {this.renderEsito()}
          </div>
          }
        </div>
      </div>

        {/* Modale per le visualizzazione degli errori durante le richieste al backend*/}
        <ModalError isOpen={this.state.errorMessages && this.state.errorMessages.length>0}
               onCancel={()=>{this.setState({errorMessages:[]})}}
               message={this.state.errorMessages}
               modalBodyStyle={{overflowY: "unset"}}
        />
        <div style={{ 'visibility': this.state.showLoader==false? 'hidden' : 'visible' }}>
          <Loader zIndex={1060} fullPage={true} />
        </div>
      </div>
    );
  }
}

export default ResultsLive;
