import React, {Component} from "react";
import {ClinicCaseQuestionUserResult} from "../../remote/types/backend/occurrenceClinicCaseQuestion.types";

interface IResultCircle {
  data: ClinicCaseQuestionUserResult,
}

export default class ResultCircle extends Component<IResultCircle> {
  render() {
    return <div
      className="d-flex flex-column user-result-card shadow-custom align-items-center justify-content-center"
      style={{padding: "10px 15px", width: "235px"}}
    >
      <p className="mb-1" style={{fontSize: "35pt"}}>{this.props.data.nome} {" "} {this.props.data.cognome}</p>
      <div style={{fontSize: "35pt"}}>
        {this.props.data.response[0]}
      </div>
    </div>
  }
}
