import React, {Component} from 'react';
import img_tavolo_a from '../../assets/results/TavoloA_arancio.png';
import img_tavolo_b from '../../assets/results/TavoloB_blu.png';
import img_tavolo_c from '../../assets/results/TavoloC_viola.png';
import img_tavolo_d from '../../assets/results/TavoloD_giallo.png';
import img_tavolo_grigio from '../../assets/results/Tavolo_grigio.png';
import img_tavolo_bianco from '../../assets/results/tavolo_outline.png';
import {TableColorStatus} from "./Results.types";

function getTableColor(mode: TableColorStatus) {
  switch (mode) {
    case "WHITE":
      return "transparent";
    case "GREY":
      return "#6a6a6a";
    case "A":
      return "#e52421";
    case "B":
      return "#0040b0";
    case "C":
      return "#5e17f4";
    case "D":
      return "#f48f00";
  }
}

export interface IResultsTableProps {
  mode: TableColorStatus;
  text: string;
  containerProps: any;
}

function getTableImage(mode: TableColorStatus): string {
  switch (mode) {
    case "WHITE":
      return img_tavolo_bianco;
    case "GREY":
      return img_tavolo_grigio;
    case "A":
      return img_tavolo_a;
    case "B":
      return img_tavolo_b;
    case "C":
      return img_tavolo_c;
    case "D":
      return img_tavolo_d;
  }
}

interface ITableSeparatorProps {
  table: TableColorStatus
}

function TableSeparator(props: ITableSeparatorProps) {
  const {table} = props;
  return (<div
    style={{
      width:"98%",
      height:"2px",
      marginTop: "1px",
      alignSelf: "center",
      visibility: table === "WHITE" ? "hidden" : undefined,
      backgroundColor: getTableColor(table),
    }}
  />)
}

export default class ResultsTable extends Component<IResultsTableProps> {
  render() {
    return (
      <div className="d-flex flex-column">
        <img src={getTableImage(this.props.mode)} alt={`tavolo-${this.props.mode}`} />
        <TableSeparator table={this.props.mode}/>
        <p
          style={{alignSelf: "center", marginTop: "-12px", fontSize: "28pt"}}
        >
          {this.props.text}
        </p>
      </div>
    )
  }
}
