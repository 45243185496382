import {Enum, EnumValue} from 'ts-enums';

export class ComponentStateValue extends EnumValue {}

class ComponentStateEnum extends Enum<ComponentStateValue> {
    NOT_SET = new ComponentStateValue('NOT_SET');
    LOADING = new ComponentStateValue('LOADING');
    LOADED = new ComponentStateValue('LOADED');
    ERROR = new ComponentStateValue('ERROR');
    UPDATING = new ComponentStateValue('UPDATING');
    UPDATED = new ComponentStateValue('UPDATED');
    ERROR_UPDATING = new ComponentStateValue('ERROR_UPDATING');
    DATA_CHANGED = new ComponentStateValue('DATA_CHANGED');
    PARAMETERS_NOT_COMPLIANT = new ComponentStateValue('PARAMETERS_NOT_COMPLIANT');

    constructor() {
        super();
        this.initEnum('ComponentState')
    }
}

const ComponentState = new ComponentStateEnum();

export default ComponentState;
