/*
 * Common functional utilities
 */

import {get, isString} from "lodash";
import moment from "moment";

const tryApply = (f, options = {defVal: 'KEEP_FIRST'}, ...args) => {
  try {
    return f(...args);
  } catch(e) {
    switch(options.defVal) {
      case "KEEP_FIRST":
        return args.length > 0 ? args[0] : null;
      case "KEEP":
        return args;
      default:
        return null;
    }
  }
};

const tryApplyUnary = (f, arg, options = {defVal: 'KEEP('}) => {
  try {
    if (Array.isArray(arg)) {
      return arg.map(f);
    } else {
      return f(arg);
    }
  } catch(e) {
    switch (options.defVal) {
      case "KEEP":
        return arg;
      default:
        return null;
    }
  }
};

export const getAttributeInRow = (attribute, row, options = {}) => {
  const {caseSensitive = false} = options;
  let a;
  try {
    a = row && row.attributes && row.attributes[attribute];
  } catch (e) {
    a = null;
  }
  if (a == null) return null;
  if (Array.isArray(a)) a = a.length > 0 ? a[0] : null;
  return isString(a) && !caseSensitive ? a.toLowerCase() : a;
};

const simpleCompare = (a, b, order) => {
  let res;

  // If equal return 0
  if (a === b) {
    res = 0;
  }
  // Else If one of them is null, it goes first
  else if (a == null || b == null) {
    return a == null ? -1 : 1;
  }
  // Else use the built-in sorting
  else {
    res = a < b ? -1 : 1;
  }
  return order === 'asc' ? res : -res;
};

export const sortRowByAttribute = (attribute, order, rowA, rowB, options = {}) => {
  let [a, b] = [rowA, rowB].map(x => getAttributeInRow(attribute, x, options));

  return simpleCompare(a, b, order);
};

export const tableSortCaseInsensitive = (a, b, order) => {
  let [ia, ib] = [a, b].map(x => isString(x) ? x.toLowerCase() : x);
  return simpleCompare(ia, ib, order);
};

export const extractDataDiNascita = x => moment(x, "DD/MM/YYYY");

export const paginationSizes = [
  {text: "25", value: 25},
  {text: "50", value: 50},
  {text: "100", value: 100}
]


export const extractMessageFromError = (error, defaultErrorMessage="Si è verificato un errore. Si prega di riprovare.") => {
  try{
    return error.response.data.message || defaultErrorMessage;
  }catch (e) {
    return defaultErrorMessage
  }

}

export const extractCodeFromError = (error) => {
  try{
    return error.response.data.code;
  }catch (e) {
    return 500
  }

}

