
const CONSTANTS =
  {
    COLORS:{
      getPrimaryColor(){ return "#b6cf53"},
      getSecondaryColor(){ return "#016a64"},
      getTertiaryColor(){ return "#e3097e"}
    }
  };

export default CONSTANTS;
