import React from 'react';

export const OkIcon = ({ className, ...props }) => <i className={["fa fa-check-circle-o font-2xl text-success", className].filter(x => !!x).join(" ")} {...props} />;
export const WrongIcon = ({ className, ...props }) => <i className={["fa fa-times-circle-o font-2xl text-danger", className].filter(x => !!x).join(" ")} {...props} />;

export const BoxedContainer = ({children, style, ...props}) => <div
  style={{
    borderRadius: "4px",
    padding: "8px",
    wordWrap: "break-word",
    ...style,
  }}
  {...props}
>{children}</div>;
