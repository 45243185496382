import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './App.scss';
import AuthService from "./remote/services/AuthService";
import ResultsLive from "./components/ResultsLive";
import Results from "./components/Results";
import QrCode from "./components/QrCode";
import MessaggiPubblici from "./components/MessaggiPubblici";
import RisposteCasoClinico from "./components/RisposteCasoClinico";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));


export const ComponentsKeys = {
  RESULTSLIVE: 0,
  RISPOSTE_CASO_CLINICO: 1,
  MessaggiPubblici: 2,
  Results: 3,
  Results_Live: 4,
  ResultsMean: 5,
  TimeResults: 6,
  TimeResultsRand: 7,
  UsersResults: 8,
  QrCode: 9,
  Congresses: 10,
  Home: 11,
}
class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login " render={props => <Login {...props}/>} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />

            <Route path="/results-tavoli/:congressId/:clinicCaseCode" name="Risultati" render={
              props => {
                return <ResultsLive {...props} key={ComponentsKeys.RESULTSLIVE}/>
              }
            }/>
            <Route path="/risposteCasoClinico/:congressId/:sessionId/:clinicCaseId" name="Risultati" render={
              props => {
                return <RisposteCasoClinico {...props} key={ComponentsKeys.RISPOSTE_CASO_CLINICO}/>
              }
            }/>

            <Route path="/messaggi/:congressId/:sessionId" name="Messaggi" render={
              props => {
                return <MessaggiPubblici {...props} key={ComponentsKeys.MessaggiPubblici}/>
              }
            }/>

            <Route path="/results/:congressId/:clinicCaseCode" name="Risultati" render={
              props => {
                return <Results {...props} key={ComponentsKeys.Results}/>
              }
            }/>

            <Route path="/results-live/:congressId/:clinicCaseCode" name="RisultatiLive" render={
              props => {
                return <Results autorefresh sortResponses {...props} key={ComponentsKeys.Results_Live}/>
              }
            }/>

            <Route path="/results-mean/:congressId/:clinicCaseCode" name="RisultatiMean" render={
              props => {
                return <Results type="MEAN_RESULTS" autorefresh {...props} key={ComponentsKeys.ResultsMean}/>
              }
            }/>

            <Route path="/timeResults/:congressId/:clinicCaseCode" name="Risultati tempo" render={
              props => {
                return <Results type="TIME_RESULTS" autorefresh sortResponses {...props} key={ComponentsKeys.TimeResults}/>
              }
            }/>

            <Route path="/results-rand/:congressId/:clinicCaseCode" name="Risultati tempo" render={
              props => {
                return <Results type="RAND_RESULTS" autorefresh sortResponses {...props} key={ComponentsKeys.TimeResultsRand}/>
              }
            }/>


            <Route path="/usersResults/:congressId/:clinicCaseCode" name="Risultati tempo" render={
              props => {
                return <Results type="USERS_RESULTS" autorefresh {...props} key={ComponentsKeys.UsersResults}/>
              }
            }/>

            <Route path="/qrCode" name="QrCode" render={
              props => {
                return <QrCode {...props} key={ComponentsKeys.QrCode}/>
              }
            }/>

            <Route path="/congress" name="Congresses" render={
              props => {
                if(AuthService.getInstance().loggedIn())
                  return <DefaultLayout {...props} isCongress={true} key={ComponentsKeys.Congresses}/>
                else{
                  return <Login {...props}/>
                }
              }
            }/>

            <Route path="/" name="Home" render={
              props => {
                if(AuthService.getInstance().loggedIn())
                  return <DefaultLayout {...props} key={ComponentsKeys.Home}/>
                else{
                  return <Login {...props}/>
                }
              }
            }/>

          </Switch>
        </React.Suspense>
      </BrowserRouter>

    );
  }
}

export default App;
