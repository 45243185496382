import React, {Component} from 'react';
import logo from '../assets/img/brand/logo_2x.png'


class Loader extends Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {

    }

    render() {
        return (

            <React.Fragment>
                <div style={{
                    position: this.props.position ? this.props.position : 'fixed',
                    top: '0px',
                    right: '0px',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#413c3c',
                    backgroundRepeat: 'no-repeat',
                    // zIndex: this.props.fullPage ? '10000000' : '1',
                    zIndex: this.props.zIndex || '100',
                    opacity: '0.7',
                    filter: 'alpha(opacity=40)',
                }}>
                </div>
                <div style={{
                    position: this.props.position ? this.props.position : 'fixed',
                    top: "150px",
                    right: 0,
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    padding: '100px',
                    zIndex: this.props.zIndex + 50 || '150'
                }}>
                    <img style={{
                        width: '170px'
                    }} src={logo}/>
                </div>
            </React.Fragment>
        );
    }
}

export default Loader;
