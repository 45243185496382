import React from 'react'
import {BoxedContainer, OkIcon, WrongIcon} from "./Helpers";
import {Container, Row, Col, Progress} from 'reactstrap';

const Feature = ({children, ...props}) => <div {...props}>{children}</div>

export const ResultRow = ({
                   text, progressText, pct, progressClassName, backgroundColor, ...restProps
                 }) => (
  <BoxedContainer className="ranking-row" {...restProps}>
    <Container fluid>
      <Row>
        <Col sm={12} className="d-flex flex-row w-100">
          <div
            className="align-self-center diagnosis-card shadow-custom h-100 d-flex flex-grow-1"
          >
            <span>{text}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={11} className="d-flex">
          <div
            style={{height: "4rem", backgroundColor:backgroundColor, width:`${pct}%`}}
          >
            {""}
          </div>
        </Col>
        <Col sm={1} className="d-flex flex-column align-items-end">
          <span className="verticalAlignMiddle">{progressText}</span>
        </Col>
      </Row>
    </Container>
  </BoxedContainer>
);
