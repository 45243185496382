import axios from 'axios';
import defaultEnpoints from "../Endpoints";
import AuthService from "./AuthService";
import * as BM from '../types/backend.types';
import qs from 'qs';

class CongressesService extends AuthService {


  constructor() {
    super()
  }

  getCongresses() {
    let  promise = axios.get<any, BM.UserResponse>(defaultEnpoints.congressesEndpoint, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  getCongressById(congressId:string) {
    let  promise = axios.get<any, BM.UserResponse>(`${defaultEnpoints.congressesEndpoint}/${congressId}`, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  getCongressConfigById(congressId:string) {
    let  promise = axios.get<any, BM.UserResponse>(`${defaultEnpoints.congressesEndpoint}/${congressId}/configuration`, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  editCongress(body:string) {
    let promise = axios.put<any, BM.UserResponse>(defaultEnpoints.congressesEndpoint, body, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  updateWhitelistUtenti(body:string) {
    let promise = axios.put<any, BM.UserResponse>(`${defaultEnpoints.congressesEndpoint}/whitelist_utenti`, body, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  addCongress(body:string) {
    let promise = axios.post<any, BM.UserResponse>(defaultEnpoints.congressesEndpoint, body, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  deleteCongress(id:string) {
    let  promise = axios.delete<any, BM.UserResponse>(defaultEnpoints.congressesEndpoint, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  getStatsByIdAndUserAndClinicCaseCode(congressId: string, clinicCaseCode: string) {
    const path = `${defaultEnpoints.congressesEndpoint}/${congressId}/${clinicCaseCode}/on_air/public`;
    let promise = axios.get<any, BM.CongressClinicCaseStats>(path);
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  getLocalizedUsers = (congressId: string) => {
    const path = `${defaultEnpoints.congressesEndpoint}/${congressId}/locations?gateway=false`;
    let promise = axios.get<any, BM.CongressLocalizedUsers>(path, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  getLocationsUserCount = (congressId: string, giorno:string) => {
    let queryString = qs.stringify({giorno})
    const path = `${defaultEnpoints.congressesEndpoint}/${congressId}/locations_users_count?${queryString}`;
    let promise = axios.get<any, BM.CongressLocalizedUsers>(path, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }
  deleteLocationsUserCount = (congressId: string, giorno:string) => {
    let queryString = qs.stringify({giorno})
    const path = `${defaultEnpoints.congressesEndpoint}/${congressId}/locations?${queryString}`;
    let promise = axios.delete<any, BM.CongressLocalizedUsers>(path, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  getAutocandidature = (congressId: string) => {
    const path = `${defaultEnpoints.congressesEndpoint}/${congressId}/autocandidature`;
    let promise = axios.get<any, BM.CongressLocalizedUsers>(path, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  rispondiAutocandidatura(autocandidaturaId:string, accettata:boolean) {
    let queryString = qs.stringify({
      id:autocandidaturaId,
      accettata:!!accettata
    })
    let promise = axios.post<any, BM.UserResponse>(`${defaultEnpoints.congressesEndpoint}/rispondi_autocandidatura?${queryString}`, {}, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  getQuestionsAnswerCount(congressId: string, clinicCaseCode: string) {
    const path = `${defaultEnpoints.congressesEndpoint}/${congressId}/${clinicCaseCode}/on_air/questions_answers_count`;
    let promise = axios.get<any, BM.CongressClinicCaseStats>(path, {'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  sendPush(congressId: string, body: { userIds: string[], title: any, message: string, homeDataCode:string }) {
    const path = `${defaultEnpoints.congressesEndpoint}/${congressId}/users-push-message`;
    let promise = axios.post<any, BM.CongressClinicCaseStats>(path, body,{'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  updateHeaderQuesitonari(congressId: string, body: string) {
    const path = `${defaultEnpoints.congressesEndpoint}/${congressId}/header_questionati`;
    let promise = axios.patch<any, BM.CongressClinicCaseStats>(path, body,{'headers':  this.getLoggedInHeaders()});
    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }



}

export default CongressesService;
