import React, {Component} from 'react';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import QRCode from 'qrcode'
import qs from "qs";
import {extractMessageFromError} from "../utility/common";
import ComponentStatus from "../model/ComponentStatus";
import CongressesService from "../remote/services/CongressesService";
import {Alert} from "reactstrap";


const qrCodeStyleConfiguration= {
  "nomeMarginBottom": "0px",
  "nomeFontsize": "2.5em",
  "nomeFontWeight": "bold",
  "nomeTextTransform": "uppercase",

  "cognomeMarginBottom": "0px",
  "cognomeFontsize": "2.5em",
  "cognomeFontWeight": "bold",
  "cognomeTextTransform": "uppercase",

  "imgStyleWidth": "170px",
  "imgStylePaddingTop":"0.5em",

  "mainContainerBackgroundColor":"white",
  "mainContainerHeight":"100vh",

  "divTextAlign":"center",
  "divPaddingTop":"260px",
}
class QrCode extends Component {

  /**
   * COMPONENT LIFECYCLE METHODS
   */

  constructor(props){
    super(props);
    const queryParams = qs.parse(this.props.location.search, {ignoreQueryPrefix: true})
    console.log(queryParams)
    this.congressId = queryParams.congressId;
    this.congressService = new CongressesService();
    this.state = {
      nome:queryParams.nome,
      cognome:queryParams.cognome,
      hash:queryParams.hash,
    }
  }

  componentDidMount() {
    QRCode.toDataURL(this.state.hash, {type: "png", width:"500"})
      .then(imgData => {
        this.setState({imgSrc: imgData})
      })
      .catch(e => {
        console.error(e)
      })

    this.congressService.getCongressById(this.congressId)
      .then(res => {
        this.setState({
          qrCodeConfiguration:res.data.configuration.qrCodeConfiguration
        })
      })
      .catch(error =>{
        const message = extractMessageFromError(error, "Si è verificato un errore. Si prega di chiudere e riaprire la pagina");
        this.setState({
          errorMessages: [...this.state.errorMessages, message],
          componentStatus: ComponentStatus.ERROR
        })
      })
  }


  showLoader = (show) => {
    this.setState({showLoader:show})
  }

  /**
   * RENDER
   */

  render() {
    const {nome, cognome, qrCodeConfiguration} = this.state;

    if(qrCodeConfiguration===undefined){
      return <div style={{display:"flex", marginTop:"150px", justifyContent:"center"}}>
        <Alert color={"danger"}><h4>Configurazione del QRCode non presente.</h4></Alert>
      </div>
    }

    const {
      nomeFontsize="unset", nomeFontWeight="unset", nomeMarginBottom="unset", nomeTextTransform="unset",
      cognomeFontsize="unset", cognomeFontWeight="unset", cognomeMarginBottom="unset", cognomeTextTransform="unset",
      imgStyleWidth="unset", imgStylePaddingTop="unset",
      mainContainerBackgroundColor="unset", mainContainerHeight="unset",
      divTextAlign="unset", divPaddingTop="unset"
    } = this.state.qrCodeConfiguration;

    const nomeStyle={marginBottom: nomeMarginBottom, display: "inline", fontSize: nomeFontsize, fontWeight: nomeFontWeight, textTransform:nomeTextTransform};
    const cognomeStyle={fontSize: cognomeFontsize, marginBottom: cognomeMarginBottom, textTransform:cognomeTextTransform, fontWeight: cognomeFontWeight};
    const imgStyle={width: imgStyleWidth, paddingTop:imgStylePaddingTop};
    const mainContainerStyle={backgroundColor:mainContainerBackgroundColor, height:mainContainerHeight};
    const divStyle={textAlign:divTextAlign, paddingTop:divPaddingTop};

    return (
      <div id="mainContainer" className="animated fadeIn" style={mainContainerStyle}>
        <div style={divStyle}>
          <h2 style={{...nomeStyle,  display: "inline"}}>{nome}</h2>
          <h2 style={cognomeStyle}>{cognome}</h2>
          {this.state.imgSrc!=undefined &&
            <img style={imgStyle} src={this.state.imgSrc} alt={""}/>
          }
        </div>
      </div>
    );
  }
}

export default QrCode;
