import React, {Component} from 'react';
import {AnswerKeys, TableKeys} from "./Results.types";
import {computePercentages} from "./utils";

function getBarColor(mode: AnswerKeys) {
  switch (mode) {
    case "A":
      return "#ff7300";
    case "B":
      return "#0040ff";
    case "C":
      return "#7b0bff";
    case "D":
      return "#fbc94f";
  }
}

interface IBarProps {
  answer: AnswerKeys;
  pct: number;
}

function Bar(props: IBarProps) {
  return <div
    style={{
      height: "100%",
      width: `${props.pct}%`,
    }}
  >
    <div
      className="d-flex"
      style={{
        backgroundColor: getBarColor(props.answer),
        color: "whitesmoke",
        marginLeft: "2px",
        marginRight: "2px",
        height: "100%"
      }}
    >
      <span
        style={{
          margin: "auto",
          fontSize: "22pt",
          fontWeight: 500,
        }}
      >
        {props.answer}
      </span>
    </div>
  </div>
}

interface IResultsBarProps {
  results: {[k in AnswerKeys]: number}
}

export default class ResultsBar extends Component<IResultsBarProps> {

  render () {
    const answers: AnswerKeys[] = ["A", "B", "C", "D"];
    const pcts = computePercentages(answers.map(k => this.props.results[k]));

    return (
      <div
        className="d-flex"
        style={{
          height: "48px",
          width: "100%",
          backgroundColor: "transparent",
        }}
      >
        {
          answers.map((k, i) => pcts[i] === 0 ? null : <Bar key={`pct-bar-${k}`} pct={pcts[i]} answer={k}/>)
        }
      </div>
    )
  }
}
