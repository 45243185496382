
// Source https://gist.github.com/engelen/fbce4476c9e68c52ff7e5c2da5c24a28
import {head, sum} from "lodash";
import {AnswerKeys} from "./Results.types";

export function argMax(array: number[]) {
  return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
}

export function computePercentages(counts: number[]) {
  const total = sum(counts);
  if (total === 0) {
    return counts.map(() => 0);
  }
  else {
    const pcts = counts.map(x => Math.floor(x / total * 100));
    const remainder = 100 - sum(pcts);
    const maxPctIndex = argMax(pcts);
    pcts[maxPctIndex] += remainder;
    return pcts;
  }
}

export function matchResponse(response: string): AnswerKeys | null {
  switch (head(response)) {
    case "A":
      return "A";
    case "B":
      return "B";
    case "C":
      return "C";
    case "D":
      return "D";
    default:
      return null;
  }
}
