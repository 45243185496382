import React, {Component} from 'react';
import {Card, CardHeader, Col, Container, Row,} from 'reactstrap';
import ComponentStatus from "../model/ComponentStatus"
import {strings} from "../resources/strings";
import {chunk, filter, get, includes, orderBy, partition, sum, zip} from 'lodash'
import Loader from "../utility/Loader";
import CongressesService from "../remote/services/CongressesService";
import {AppHeader, AppNavbarBrand} from "@coreui/react";
import {ResultRow} from "./Results/ResultRow";
import parentLogo from '../assets/img/parent_logo.png';
import horizontalLogo from '../assets/img/brand/horizontal_logo.png';
import {TimeResultRow} from "./Results/TimeResultRow";
import {ResultCard, ResultMessage} from "./Results/Result.components";
import ResultCircle from "./Results/ResultCircle";
import ModalError from "./utility/ModalError";
import {extractCodeFromError, extractMessageFromError} from "../utility/common";
import ClinicCasesService from "../remote/services/ClinicCasesService";
import CONSTANTS from "../model/Constants";
import {defaults} from 'react-chartjs-2';
import Histogram from "./Results/Histogram";

defaults.global.defaultFontSize = 35;
const mockStatsResponse = {
  "code": 200,
  "message": "The live question of the specified clinic case code",
  "result": [
    {
      "id": "21d952ec-0204-44df-bb1b-d7581247421e",
      "question": "Mauris felis lorem, malesuada non dapibus nec, scelerisque ac tellus. Fusce eget eros faucibus, lacinia tortor ut, euismod tellus. Nullam malesuada venenatis congue. Donec non porttitor lorem, eget bibendum dui. Fusce auctor, libero vel placerat convallis, nibh urna suscipit orci, ac ultricies mauris nibh a tortor. Cras lobortis augue ut pulvinar lobortis",
      "title": "Domanda valutazione",
      "offSet": [
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10"
      ],
      "onSet": "1",
      "results": [
        {
          "id": "ddb0a1a7-2fe1-4f6b-bff4-b3f899e8be9a",
          "response": "2",
          "percentage": 0,
          "count": 0,
          "isCorrect": false
        },
        {
          "id": "eea77468-8f11-495c-aae7-93e3a4307b29",
          "response": "3",
          "percentage": 0,
          "count": 0,
          "isCorrect": false
        },
        {
          "id": "679b80fb-db5f-4d3d-865f-f3cb6c3e886e",
          "response": "4",
          "percentage": 100,
          "count": 1,
          "isCorrect": false
        },
        {
          "id": "8a43918d-2472-4514-b095-220274aee178",
          "response": "5",
          "percentage": 0,
          "count": 0,
          "isCorrect": false
        },
        {
          "id": "ebded5cc-8cda-4e40-aa15-e262f31190a2",
          "response": "6",
          "percentage": 0,
          "count": 0,
          "isCorrect": false
        },
        {
          "id": "56510c70-eacf-4c60-8ccf-12ff2ac48ae7",
          "response": "7",
          "percentage": 0,
          "count": 0,
          "isCorrect": false
        },
        {
          "id": "cb4c0994-1383-4ba6-bbc1-68edad75b643",
          "response": "8",
          "percentage": 0,
          "count": 0,
          "isCorrect": false
        },
        {
          "id": "09b77141-8857-4cd5-80cf-156cc82da560",
          "response": "9",
          "percentage": 0,
          "count": 0,
          "isCorrect": false
        },
        {
          "id": "88ee1ffe-bd5d-480c-9a49-003803014dbe",
          "response": "10",
          "percentage": 0,
          "count": 0,
          "isCorrect": false
        },
        {
          "id": "30afc44c-5394-4246-afc7-3e1169ef5660",
          "response": "1",
          "percentage": 0,
          "count": 0,
          "isCorrect": true
        },
        {
          "id": "3fb637d4-5265-40eb-b7ea-7f6c93231bd7",
          "response": "Nessuna risposta",
          "percentage": 0,
          "count": 0,
          "isCorrect": false
        }
      ],
      "timeResults": [
        {
          "id": "01e1e4e9-540a-4aac-96c9-64e2dcee78b71",
          "response": "1",
          "username": "utente 1",
          "titolo": null,
          "nome": "Marco",
          "cognome": "Rossi",
          "secondiImpiegati": 1000,
          "dataRisposta": null
        },
        {
          "id": "01e1e4e9-540a-4aac-96c9-64e2dcee78b72",
          "response": "1",
          "username": "utente 2",
          "titolo": null,
          "nome": "Marco",
          "cognome": "Rossi",
          "secondiImpiegati": 3000,
          "dataRisposta": null
        },
        {
          "id": "01e1e4e9-540a-4aac-96c9-64e2dcee78b73",
          "response": "1",
          "username": "utente 3",
          "titolo": null,
          "nome": "Marco",
          "cognome": "Rossi",
          "secondiImpiegati": 2000,
          "dataRisposta": null
        },
        {
          "id": "01e1e4e9-540a-4aac-96c9-64e2dcee78b74",
          "response": "1",
          "username": "utente 4",
          "titolo": null,
          "nome": "Marco",
          "cognome": "Rossi",
          "secondiImpiegati": 1000,
          "dataRisposta": null
        },
        {
          "id": "01e1e4e9-540a-4aac-96c9-64e2dcee78b75",
          "response": "1",
          "username": "utente 5",
          "titolo": null,
          "nome": "Marco",
          "cognome": "Rossi",
          "secondiImpiegati": 50,
          "dataRisposta": null
        },
        {
          "id": "01e1e4e9-540a-4aac-96c9-64e2dcee78b76",
          "response": "1",
          "username": "utente 6",
          "titolo": null,
          "nome": "Marco",
          "cognome": "Rossi",
          "secondiImpiegati": 1,
          "dataRisposta": null
        }
      ],
      "userResults": [
        {
          "id": null,
          "response": "B. 2     ",
          "username": "user1",
          "nome": "team",
          "cognome": "B",
          "dataRisposta": "2022-11-15T08:54:37.363",
          "isCorrect": true
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user2",
          "nome": "1668502492321",
          "cognome": "1668502492321",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user3",
          "nome": "team",
          "cognome": "A",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user4",
          "nome": "team",
          "cognome": "F",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user5",
          "nome": "team",
          "cognome": "C",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user6",
          "nome": "team",
          "cognome": "D",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user7",
          "nome": "1668502492321",
          "cognome": "1668502492321",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user8",
          "nome": "1668502492321",
          "cognome": "1668502492321",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user9",
          "nome": "1668502492321",
          "cognome": "1668502492321",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user10",
          "nome": "1668502492321",
          "cognome": "1668502492321",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user11",
          "nome": "1668502492321",
          "cognome": "1668502492321",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
        {
          "id": null,
          "response": "A. 3",
          "username": "user12",
          "nome": "1668502492321",
          "cognome": "1668502492321",
          "dataRisposta": "2022-11-15T08:55:06.884",
          "isCorrect": false
        },
      ],
      "mean": 4,
      "status": "VISUALIZZAZIONE_RISULTATI"
    }
  ]
}
const DO_MOCK = false && process.env.REACT_APP_MOCK=="true";

const REFRESH_RATE_MS = 3000;

const maximumLengths = [
  { val: 0, minPct: 0.03 },
  { val: 10, minPct: 0.04 },
  // { val: 100, minPct: 0.05 },
];

const computeLength = (pct, value) => Math.max(pct, Math.max(...maximumLengths.filter(x => x.val <= value).map(x => x.minPct)));

const NESSUNA_RISPOSTA_STRING = "nessuna risposta";
const RISPOSTA_VUOTA_STRING = " ";

// Source https://gist.github.com/engelen/fbce4476c9e68c52ff7e5c2da5c24a28
function argMax(array) {
  return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
}

function computePercentages(counts) {
  const total = sum(counts);
  if (total === 0) {
    return counts.map(() => 0);
  }
  else {
    const pcts = counts.map(x => Math.floor(x / total * 100));
    const remainder = 100 - sum(pcts);
    const maxPctIndex = argMax(pcts);
    pcts[maxPctIndex] += remainder;
    return pcts;
  }
}

class Results extends Component {

  /**
   * COMPONENT LIFECYCLE METHODS
   */

  constructor(props){
    super(props);
    this.state = {
      showModal:false,
      componentStatus:ComponentStatus.LOADING,
      errorMessages:[],
      clinicCaseStats: undefined,
    }
    this.congressesService = new CongressesService();
    this.clinicCaseService = new ClinicCasesService();
  }

  getCongressId = () => {
    return this.props.match.params.congressId;
  }

  getClinicCaseCode = () => {
    return this.props.match.params.clinicCaseCode;
  }

  componentDidMount() {
    let reqs = [
      this.congressesService.getCongressConfigById(this.getCongressId()),
      this.clinicCaseService.getConfigClinicCaseByCode(this.getClinicCaseCode()),
    ]

    Promise.all(reqs)
      .then(res => {
        let graphicCongressConfiguration = get(res[0].data, 'graphicConfiguration', undefined);
        let casoClinicoConfiguration = res[1].data;

        let logoToShow = get(res[0].data, 'graphicConfiguration.logoDashboard', undefined);
        if(logoToShow==undefined || logoToShow.trim()=="")
          this.setState({
            logoToShow:horizontalLogo
          })
        else
          this.setState({
            logoToShow
          })

        this.setColors(graphicCongressConfiguration, casoClinicoConfiguration)

      })
      .catch(e =>{
        console.error(e);
      })

    this.getData()
  }

  componentWillUnmount() {
    try {
      clearInterval(this.refreshDataInterval);
    } catch (e) {
    }
  }

  showLoader = (show) => {
    this.setState({showLoader:show})
  }

  setColors = (graphicCongressConfiguration, casoClinicoConfiguration) => {

    const rispostaCorrettaField = casoClinicoConfiguration.coloreRispostaEsatta;
    const rispostaErrataField = casoClinicoConfiguration.coloreRispostaErrata;

    let coloreRispostaCorretta, coloreRispostaErrata, coloreTerziario;

    switch (rispostaCorrettaField.toUpperCase()){
      case "PRIMARY":
        coloreRispostaCorretta=get(graphicCongressConfiguration, 'colorePrimario', CONSTANTS.COLORS.getPrimaryColor());
        break;
      case "SECONDARY":
        coloreRispostaCorretta=get(graphicCongressConfiguration, 'coloreSecondario', CONSTANTS.COLORS.getSecondaryColor());
        break;
      case "TERTIARY":
        coloreRispostaCorretta=get(graphicCongressConfiguration, 'coloreTerziario', CONSTANTS.COLORS.getTertiaryColor());
        break;
    }

    switch (rispostaErrataField.toUpperCase()){
      case "PRIMARY":
        coloreRispostaErrata=get(graphicCongressConfiguration, 'colorePrimario', CONSTANTS.COLORS.getPrimaryColor());
        break;
      case "SECONDARY":
        coloreRispostaErrata=get(graphicCongressConfiguration, 'coloreSecondario', CONSTANTS.COLORS.getSecondaryColor());
        break;
      case "TERTIARY":
        coloreRispostaErrata=get(graphicCongressConfiguration, 'coloreTerziario', CONSTANTS.COLORS.getTertiaryColor());
        break;
    }

    coloreTerziario = get(graphicCongressConfiguration, 'coloreTerziario', CONSTANTS.COLORS.getTertiaryColor());

    this.setState({
      coloreRispostaCorretta,
      coloreRispostaErrata,
      coloreTerziario
    })
  }

  /**
   * REMOTE REQUESTS
   */

  _getData = () => {
    let promise = DO_MOCK
      ? Promise.resolve({
        data: mockStatsResponse
      })
      : this.congressesService.getStatsByIdAndUserAndClinicCaseCode(this.getCongressId(), this.getClinicCaseCode());
    return promise
      .then(res => {
        /**
         * @type {OccurrenceClinicCaseQuestion[]}
         */
        const stats = res.data;

        // Prendiamo solo le statistiche che hanno dei risultati da mostrare

        if(Array.isArray(stats.results) && stats.results.length > 0){
          return {
            clinicCaseStats: [stats],
            resultState: undefined,
          }
        }
        else{
          return Promise.reject(new Error("Risposta vuota"));
        }


      })
      .catch(e => {
        console.error(e)
        const code = extractCodeFromError(e);
        if (code === 402) {
          return {
            clinicCaseStats: undefined,
            resultState: "NO_QUESTION",
          };
        } else {
          console.error(e);
          return Promise.reject(e);
        }
      })
  }

  updateData = () => {
    if (!this.refreshDataCache) {
      this.refreshDataCache =
        this._getData()
          .then(state => this.setState(state))
    .finally(() => this.refreshDataCache = undefined);
    }
  }

  getData(){
    this.showLoader(true);
    this._getData()
      .then(state => this.setState({
        ...state,
        componentStatus: ComponentStatus.LOADED
      }))
      .then(() => {
        if (this.props.autorefresh) {
          console.log("Initializing autorefresh")
          this.refreshDataInterval = setInterval(() => this.updateData(), REFRESH_RATE_MS);
        }
      })
      .catch((error) => {
        const message = extractMessageFromError(error, "Si è verificato un errore. Si prega di chiudere e riaprire la pagina");
        this.setState({
          errorMessages: [...this.state.errorMessages, message],
          componentStatus: ComponentStatus.ERROR
        })
      })
      .finally(() => {
        this.showLoader(false)
      })

/*    return this.gatewayService.getGateways().then(response => {
      console.log(`Users - response: ${JSON.stringify(response.data)}`)
      let gateways = response.data.result.map((cur)=>{delete(cur.roles); delete(cur.hash); return cur})
      this.showLoader(false);
      this.setState({originalGateways: cloneDeep(gateways), gateways: gateways, componentStatus:ComponentStatus.LOADED
      })
    }).catch(err =>{
      console.log(`Users - err: ${err}`)
      this.showLoader(false);
      this.setState({originalGateways:[], gateways: [], componentStatus:ComponentStatus.ERROR,
        errorMessages: [...this.state.errorMessages, strings.gateway.errors.loading]})
    })*/

  }

  /**
   *
   * @param {OccurrenceClinicCaseQuestion} clinicCaseQuestionStats
   * @returns {JSX.Element}
   */
  renderClinicCaseResults = (clinicCaseQuestionStats) => {
    switch(this.props.type) {
      case "RAND_RESULTS":
        return this.renderRANDResults(clinicCaseQuestionStats);
      case "TIME_RESULTS":
        return this.renderTimeResults(clinicCaseQuestionStats);
      case "MEAN_RESULTS":
        return this.renderMeanResults(clinicCaseQuestionStats);
      case "USERS_RESULTS":
        return this.renderUsersResults(clinicCaseQuestionStats);
      case "RESULTS":
      default:
        return this.renderResults(clinicCaseQuestionStats);
    }
  }

  /**
   *
   * @param clinicCaseQuestionStats
   * @returns {ClinicCaseQuestionResult[]}
   */
  resultsToShow = (clinicCaseQuestionStats) => {
    const results = get(clinicCaseQuestionStats, "results", []);
    const [corrects, rest] = partition(results, x => x.isCorrect);

    return [...corrects, ...rest].filter(x => !!x
      && x.response.toLowerCase() !== NESSUNA_RISPOSTA_STRING
      && x.response !== RISPOSTA_VUOTA_STRING);
  }

  /**
   *
   * @param {ClinicCaseQuestionResult[]} resultsToShow
   * @returns {string}
   */
  votersNumberTooltip = (resultsToShow) => {
    return this.props.autorefresh && `Numero votanti: ${sum(resultsToShow.map(x => x.count))}`
  }

  renderResults = (clinicCaseQuestionStats) => {
    let resultsToShow = this.resultsToShow(clinicCaseQuestionStats);
    if (this.props.sortResponses) {
      resultsToShow = orderBy(resultsToShow, [x => x.response ? x.response[0] : ""])
    }

    const total = sum(resultsToShow.map(x => x.count));

    const resultBgColor_ResponseOK = ""
    const resultBgColor_ResponseKO = ""

    return <ResultCard
      headerText={clinicCaseQuestionStats.question}
      headerTooltip={this.votersNumberTooltip(resultsToShow)}
    >
      {resultsToShow.length > 0 &&
        zip(resultsToShow, computePercentages(resultsToShow.map(x => x.count))).map(([r, pct], i) =>
            // <span>{JSON.stringify({pct: r.count, text:r.response, progressText: r.percentage})}</span>
          {
            let backgroundColor = r.isCorrect===true ? this.state.coloreRispostaCorretta : this.state.coloreRispostaErrata;

            try{
              if(this.state.clinicCaseStats[0].status==="SOTTOMESSA"){
                backgroundColor = this.state.coloreTerziario
              }
            }catch (e){

            }

            return [
              <ResultRow
                pct={total === 0 ? " 0" : pct}
                text={r.response}
                progressText={`${pct}%`}
                progressClassName={total === 0 ? "empty-progress" : undefined}
                backgroundColor={backgroundColor}
              />,
              // i === resultsToShow.length - 1 ? undefined : <hr />
            ].filter(x => !!x);
          }
        )
      }
    </ResultCard>
  }

  renderUsersResults = (clinicCaseQuestionStats) => {
    /**
     * @type {ClinicCaseQuestionUserResult[]}
     */
    const resultsToShow = orderBy(
      get(clinicCaseQuestionStats, "userResults", []),
      [x => get(x, "cognome", "")]
    );

    /**
     * @type {ClinicCaseQuestionUserResult[][]}
     */
    const rowsItems = chunk(resultsToShow, 4);

    return <ResultCard
      headerText={clinicCaseQuestionStats.question}
      headerStyle={{fontSize: "35pt"}}
      headerTooltip={this.votersNumberTooltip(this.resultsToShow(clinicCaseQuestionStats))}
    >
      {rowsItems.map((row, i, ar) => (
        <Row key={`row-${i}`} style={i === 0 ? {marginTop: "70px"} : {marginTop: "85px"}}>
          {row.map((x, j) => (
            <Col className="d-flex flex-column align-items-center">
              <ResultCircle
                data={x}
              />
            </Col>
          ))}
        </Row>
      ))}
    </ResultCard>
  }

  renderRANDResults = (clinicCaseQuestionStats) => {
    let resultsToShow = this.resultsToShow(clinicCaseQuestionStats);
    if (this.props.sortResponses) {
      resultsToShow = orderBy(resultsToShow, [x => x.response ? x.response[0] : ""])
    }

    const group1_3Data = [];
    const group4_6Data = [0,0,0];
    const group7_9Data = [...group4_6Data,0,0,0];

    resultsToShow.forEach(x => {
      if (includes(["1","2","3"], x.response)) {
        group1_3Data.push(x.count)
      } else if (["4", "5", "6"].includes(x.response)) {
        group4_6Data.push(x.count)
      } else if (["7", "8", "9"].includes(x.response)) {
        group7_9Data.push(x.count)
      }
    });

    const randResults = get(clinicCaseQuestionStats, "randResults", {});

    return (
      <div style={{display:"flex", flexDirection:"column"}}>
        <span style={{fontSize:"25px", paddingLeft:"10px", paddingRight:"10px"}}>{clinicCaseQuestionStats.question}</span>
        <Histogram
          randResults={randResults}
          group1_3Data={group1_3Data}
          group4_6Data={group4_6Data}
          group7_9Data={group7_9Data}

        />
      </div>
    )
  }

  renderMeanResults = (clinicCaseQuestionStats) => {
    let meanToShow = get(clinicCaseQuestionStats, "mean", undefined)
    if(meanToShow!=undefined){
      meanToShow=meanToShow.toFixed(2)
    }

    return (
      <ResultCard
        headerText={clinicCaseQuestionStats.question}
        headerTooltip={this.votersNumberTooltip(this.resultsToShow(clinicCaseQuestionStats))}
      >
        {meanToShow &&
          <ResultMessage>
            <span className="mx-auto" style={{padding: "1em", fontSize: "32pt"}}>
              Valutazione media:&nbsp;{meanToShow}
            </span>
          </ResultMessage>
        }
      </ResultCard>
    )
  }

  renderTimeResults = (clinicCaseQuestionStats) => {

    /**
     * @type {ClinicCaseQuestionTimeResult[]}
     */
    const resultsToShow = orderBy(
      // Scartiamo le righe con username === null
      get(clinicCaseQuestionStats, "showTimeResults.cliniCaseQuestionTimeResultDTOList", [])
        .filter(x => get(x, "username", null) !== null),
      "secondiImpiegati",
      "asc"
    );

    let risposteCorrette = []
    let headerText = "Nessuna risposta corretta";

    try{
      risposteCorrette = filter(this.state.clinicCaseStats[0].risposte, {'rispostaEsatta':true});
      if(risposteCorrette.length>1){
        headerText= (
          <React.Fragment>
            <span>{"Risposte Corrette: "}</span>
            {risposteCorrette.map(x => {
              return(
                <React.Fragment>
                  <br/>
                  <span>{x.testoRisposta}</span>
                </React.Fragment>
              )

            })}
          </React.Fragment>
        )
      }
      else if(risposteCorrette.length===1){
        headerText= (
          <span>{`Risposta Corretta: ${risposteCorrette[0].testoRisposta}`}</span>
        )
      }


    }catch (e) {

    }


    return <React.Fragment>
      <ResultCard
        headerText={clinicCaseQuestionStats.question}
        hideBody
        style={{marginBottom: "2px"}}
      >
      </ResultCard>
      <ResultCard
        headerStyle={{fontSize: "21pt"}}
        headerText={headerText}
        bodyStyle={{fontSize: "17pt", padding: "2px"}}
      >
        {resultsToShow.length > 0 &&
          resultsToShow.map((curResult, i) => {
              let minutes, seconds, milliseconds;
              minutes = Math.floor((curResult.secondiImpiegati / 1000 / 60 / 60) * 60);
              seconds = Math.floor(((curResult.secondiImpiegati / 1000 / 60 / 60) * 60 - minutes) * 60);
              milliseconds = parseInt((curResult.secondiImpiegati % 1000) / 100)

              const timeToShow = (minutes < 10 ? "0" : "") + minutes + "." + (seconds < 10 ? "0" : "") + seconds + "." + milliseconds
              const nomeCognome = curResult.nome != undefined ? ((i + 1) + "° " + curResult.nome + " " + curResult.cognome) : "-"
              return [
                <TimeResultRow
                  text={nomeCognome}
                  time={`${timeToShow}`}
                  style={{marginTop: 0}}
                />,
                // i === resultsToShow.length - 1 ? undefined : <hr style={{marginTop: "1px", marginBottom: "1px"}}/>
              ].filter(x => !!x);
            }
          )
        }
      </ResultCard>
    </React.Fragment>
  }

  get checkResultStatus() {
    if (this.props.checkResultStatus !== undefined) {
      return this.props.checkResultStatus;
    } else {
      return !this.props.autorefresh;
    }
  }

  canShowContents = () => {
    if (this.checkResultStatus) {
      return this.state.clinicCaseStats && this.state.clinicCaseStats.some(x => x.status === "VISUALIZZAZIONE_RISULTATI")
    } else {
      return true;
    }
  }

  renderErrorMessage = (msg) => {
    return <Card className="results-question-card">
      <CardHeader className="results-question-card-header shadow-custom"  style={{display:"flex"}}>
            <span className="mx-auto">
              {msg}
            </span>
      </CardHeader>
    </Card>
  }

  renderContents() {
    if (this.state.resultState === "NO_QUESTION") {
      return this.renderErrorMessage("Nessuna Sessione Interattiva In Corso")
    } else if (this.state.componentStatus == ComponentStatus.LOADED && !this.state.clinicCaseStats) {
      return this.renderErrorMessage("Non ci sono Sessioni Interattive")
    }

    return <>
      {this.state.clinicCaseStats && this.canShowContents() &&
        this.state.clinicCaseStats.map(x => this.renderClinicCaseResults(x))
      }
    </>
  }

  /**
   * RENDER
   */

  render() {

    return (
      <div className="animated fadeIn d-flex flex-column" style={{minHeight: "100vh"}}>
        <div className={"customNavbar"} style={{display:"flex", justifyContent:"center", height:"30px"}}>
          <img src={parentLogo} style={{width: "auto", height: "15px", alt: strings.platformName, marginBottom:"auto", marginTop:"auto"}} alt={""}/>
        </div>

        <Container fluid style={{paddingTop: "0px"}} className="flex-grow-1 bg-white">
        <Row>
          <Col className="d-flex justify-content-center" style={{paddingTop: "0px", paddingBottom: "20px"}}>
            {this.state.logoToShow &&
              <img src={this.state.logoToShow} alt="logo" style={{height: "120px"}}/>
            }
          </Col>
        </Row>
        <Row>
          <Col>
            {this.renderContents()}
          </Col>
        </Row>
        </Container>

        {/* Modale per le visualizzazione degli errori durante le richieste al backend*/}
        <ModalError isOpen={this.state.errorMessages && this.state.errorMessages.length>0}
               onCancel={()=>{this.setState({errorMessages:[]})}}
               message={this.state.errorMessages}
               modalBodyStyle={{overflowY: "unset"}}
        />


        <div style={{ 'visibility': this.state.showLoader==false? 'hidden' : 'visible' }}>
          <Loader zIndex={1060} fullPage={true} />
        </div>


      </div>
    );
  }
}

export default Results;
