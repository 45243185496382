import axios from 'axios';
import defaultEnpoints from "../../remote/Endpoints";
import AuthService from "./AuthService";


const endpoint = defaultEnpoints.clinicCasesEndpoint;
const resourcePath = "/clinic-case";


class ClinicCasesService extends AuthService {


  constructor() {
    super()
  }

  getClinicCases(congressId:string) {

    let promise = axios.get(endpoint+congressId+resourcePath, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  getConfigClinicCaseByCode(clinicCaseCode:string) {

    let promise = axios.get(endpoint+"occurrence-clinic-case/"+clinicCaseCode+"/configurazione/", {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  createClinicCase(congressId:string, body:string) {

    let promise = axios.post(endpoint+congressId+resourcePath, body, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

  deleteClinicCase(congressId:string, clinicCaseId:string) {

    let body = [clinicCaseId];

    let promise = axios.delete(endpoint+congressId+resourcePath, {'data': body, 'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }


  updateClinicCase(congressId:string, body:string) {

    let promise = axios.put(endpoint+congressId+resourcePath, body, {'headers':  this.getLoggedInHeaders()});

    return (promise.then(res =>{
      return this._checkStatus(res);
    }))
  }

}

export default ClinicCasesService;
