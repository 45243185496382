import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {Component} from 'react';

const strings = {
  annulla: "Chiudi"
}

//definire un interfaccia per le props, di nome PropsType.
interface PropsType {
  isOpen: boolean,
  className?: string,
  title?: string,
  message?: string | string[],
  onCancel?: () => void,
  cancelMsg?: string,
  cancelColor?: string,
  onSuccess?: () => void,
  onSuccessMsg?: string,
  isLoading?: boolean,
  modalBodyStyle?: any,
  style?: any
}

//definire un interfaccia per lo state.
interface StateType {

}

class ModalError extends Component<PropsType, StateType>{

  //costruttore che inizializza lo stato.
  constructor(props: PropsType, state: StateType) {
    super(props)

    this.state = {

    }
  }

  render() {
    const height = window.innerHeight - 200
    return (
      <Modal isOpen={this.props.isOpen}
             backdrop={!!this.props.onCancel}
             className={'modal-sm ' + this.props.className}
             style={this.props.style}>
        <ModalHeader  className={'modal-header-error'}>
          <div className={"modal-header-error-icon"}>
            <img src={'/assets/img/icon_close.png'} className="img-error" alt="Icone di errore"/>
          </div>
        </ModalHeader>
        <ModalBody className={"modal-body-error"} style={{maxHeight:height, overflowY:"scroll", ...this.props.modalBodyStyle}}>

          <h4>Ooops!</h4>
          {this.props.children !== undefined ? this.props.children :

            Array.isArray(this.props.message) ?

              <React.Fragment>
                {
                  this.props.message.map((cur) => {
                    return (
                      <p>{cur}</p>
                    )
                  })
                }
              </React.Fragment>
              :
              <p>{this.props.message}</p>
          }

        </ModalBody>
        <ModalFooter>

          {this.props.onCancel &&
            <Button className="buttonPrimaryCustomColor"
                    onClick={this.props.onCancel}
                    disabled={this.props.isLoading}>
              {strings.annulla}
            </Button>
          }
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalError;
