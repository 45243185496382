import {Card, CardBody, CardHeader} from "reactstrap";
import React from "react";

export interface IResultCardProps extends React.PropsWithChildren<{}> {
  hideHeader?: boolean;
  headerText?: string;
  headerTooltip?: string;
  headerStyle?: React.CSSProperties;
  headerSpanStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  hideBody?: boolean;
  style?: React.CSSProperties;
  hideHeaderShadow?: boolean;
}

export const ResultCard = (props: IResultCardProps) => {
  const {
    hideHeader = false,
    headerText,
    headerTooltip,
    children,
    headerStyle = {},
    headerSpanStyle = {},
    bodyStyle = {},
    hideBody = false,
    style = {},
  } = props;

  return <Card className="results-question-card" style={style}>
    {!hideHeader && <CardHeader className="results-question-card-header shadow-custom" style={{display: "flex", ...headerStyle}}>
      <span title={headerTooltip} style={headerSpanStyle}>
        {headerText}
      </span>
    </CardHeader>}
    {!hideBody && <CardBody className="results-question-card-body" style={bodyStyle}>
      {children}
    </CardBody>}
  </Card>
}

export interface IResultMessageProps extends React.PropsWithChildren<{}>{
  text?: string;
}

export const ResultMessage = ({text, children}: IResultMessageProps) => <div
  className="align-self-center diagnosis-card shadow-custom h-100 d-flex flex-grow-1"
>
  {!children ? <span>{text}</span> : children}
</div>
