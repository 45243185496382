import {filter} from "lodash";

export const titles = [
  {"id":"1","text":"Dottor"},
  {"id":"2","text":"Dottoressa"},
  {"id":"3","text":"Professor"},
  {"id":"4","text":"Professoressa"},
  {"id":"5","text":"Signor"},
  {"id":"6","text":"Signora"}
];

export const professions = [
  {"id":"1","text":"Medico chirurgo"},
  {"id":"3","text":"Biologo"},
  {"id":"4","text":"Chimico"},
  {"id":"5","text":"Veterinario"},
  {"id":"6","text":"Odontoiatra"},
  {"id":"7","text":"Farmacista"},
  {"id":"9","text":"Fisico"},
  {"id":"10","text":"Psicologo"},
  {"id":"11","text":"Assistente sanitario"},
  {"id":"12","text":"Dietista"},
  {"id":"13","text":"Fisioterapista"},
  {"id":"14","text":"Educatore professionale"},
  {"id":"15","text":"Igienista dentale"},
  {"id":"16","text":"Infermiere"},
  {"id":"17","text":"Infermiere pediatrico"},
  {"id":"18","text":"Logopedista"},
  {"id":"19","text":"Ortottista/assistente di oftalmologia"},
  {"id":"20","text":"Ostetrica/o"},
  {"id":"21","text":"Podologo"},
  {"id":"22","text":"Tecnico della riabilitazione psichiatrica"},
  {"id":"23","text":"Tecnico di fisiopatologia cardiocircolatoria"},
  {"id":"24","text":"Tecnico sanitario laboratorio biomedico"},
  {"id":"25","text":"Tecnico sanitario di radiologia medica"},
  {"id":"26","text":"Tecnico audiometrista"},
  {"id":"27","text":"Tecnico audioprotesista"},
  {"id":"28","text":"Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro"},
  {"id":"29","text":"Tecnico di neurofisiopatologia"},
  {"id":"30","text":"Tecnico ortopedico"},
  {"id":"31","text":"Terapista della neuro e psicomotricità dell'età evolutiva"},
  {"id":"32","text":"Terapista occupazionale"},
  {"id":"33","text":"Ottico"},
  {"id":"34","text":"Odontotecnico"},
  {"id":"39","text":"Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare"},
  {"id":"40","text":"Tecnico della riabilitazione psichiatrica"},
  {"id":"36","text":"Altre professioni"}
];

export const specializations = [
  {"id":"1","professionId":"1","text":"Allergologia ed immunologia clinica"},
  {"id":"2","professionId":"1","text":"Angiologia"},
  {"id":"3","professionId":"1","text":"Cardiologia"},
  {"id":"4","professionId":"1","text":"Dermatologia e venereologia"},
  {"id":"5","professionId":"1","text":"Ematologia"},
  {"id":"6","professionId":"1","text":"Endocrinologia"},
  {"id":"7","professionId":"1","text":"Gastroenterologia"},
  {"id":"8","professionId":"1","text":"Genetica medica"},
  {"id":"9","professionId":"1","text":"Geriatria"},
  {"id":"10","professionId":"1","text":"Malattie metaboliche e diabetologia"},
  {"id":"11","professionId":"1","text":"Malattie dell'apparato respiratorio"},
  {"id":"12","professionId":"1","text":"Malattie infettive"},
  {"id":"13","professionId":"1","text":"Medicina e chirurgia di accettazione e di urgenza"},
  {"id":"14","professionId":"1","text":"Medicina fisica e riabilitazione"},
  {"id":"15","professionId":"1","text":"Medicina interna"},
  {"id":"16","professionId":"1","text":"Medicina termale"},
  {"id":"17","professionId":"1","text":"Medicina aeronautica e spaziale"},
  {"id":"18","professionId":"1","text":"Medicina dello sport"},
  {"id":"19","professionId":"1","text":"Nefrologia"},
  {"id":"20","professionId":"1","text":"Neonatologia"},
  {"id":"21","professionId":"1","text":"Neurologia"},
  {"id":"22","professionId":"1","text":"Neuropsichiatria infantile"},
  {"id":"23","professionId":"1","text":"Oncologia"},
  {"id":"24","professionId":"1","text":"Pediatria"},
  {"id":"25","professionId":"1","text":"Psichiatria"},
  {"id":"26","professionId":"1","text":"Radioterapia"},
  {"id":"27","professionId":"1","text":"Reumatologia"},
  {"id":"28","professionId":"1","text":"Scienza dell'alimentazione e dietetica"},
  {"id":"29","professionId":"1","text":"Audiologia e foniatria"},
  {"id":"30","professionId":"1","text":"Cardiochirurgia"},
  {"id":"31","professionId":"1","text":"Chirurgia generale"},
  {"id":"32","professionId":"1","text":"Chirurgia maxillo-facciale"},
  {"id":"33","professionId":"1","text":"Chirurgia pediatrica"},
  {"id":"34","professionId":"1","text":"Chirurgia plastica e ricostruttiva"},
  {"id":"35","professionId":"1","text":"Chirurgia toracica"},
  {"id":"36","professionId":"1","text":"Chirurgia vascolare"},
  {"id":"37","professionId":"1","text":"Ginecologia e ostetricia"},
  {"id":"38","professionId":"1","text":"Neurochirurgia"},
  {"id":"39","professionId":"1","text":"Oftalmologia"},
  {"id":"40","professionId":"1","text":"Ortopedia e traumatologia"},
  {"id":"41","professionId":"1","text":"Otorinolaringoiatria"},
  {"id":"42","professionId":"1","text":"Urologia"},
  {"id":"43","professionId":"1","text":"Anatomia patologica"},
  {"id":"44","professionId":"1","text":"Anestesia e rianimazione"},
  {"id":"45","professionId":"1","text":"Biochimica clinica"},
  {"id":"46","professionId":"7","text":"Biochimica clinica"},
  {"id":"47","professionId":"3","text":"Biochimica clinica"},
  {"id":"48","professionId":"4","text":"Biochimica clinica"},
  {"id":"49","professionId":"1","text":"Farmacologia e tossicologia clinica"},
  {"id":"50","professionId":"1","text":"Laboratorio di genetica medica"},
  {"id":"51","professionId":"3","text":"Laboratorio di genetica medica"},
  {"id":"52","professionId":"1","text":"Medicina trasfusionale"},
  {"id":"53","professionId":"1","text":"Medicina legale"},
  {"id":"54","professionId":"1","text":"Medicina nucleare"},
  {"id":"55","professionId":"1","text":"Microbiologia e virologia"},
  {"id":"56","professionId":"3","text":"Microbiologia e virologia"},
  {"id":"57","professionId":"1","text":"Neurofisiopatologia"},
  {"id":"58","professionId":"1","text":"Neuroradiologia"},
  {"id":"59","professionId":"1","text":"Patologia clinica (laboratorio di analisi chimico-cliniche e microbiologia)"},
  {"id":"60","professionId":"3","text":"Patologia clinica (laboratorio di analisi chimico-cliniche e microbiologia)"},
  {"id":"61","professionId":"4","text":"Patologia clinica (laboratorio di analisi chimico-cliniche e microbiologia)"},
  {"id":"62","professionId":"1","text":"Radiodiagnostica"},
  {"id":"63","professionId":"1","text":"Igiene, epidemiologia e sanità pubblica"},
  {"id":"64","professionId":"1","text":"Igiene degli alimenti e della nutrizione"},
  {"id":"65","professionId":"3","text":"Igiene degli alimenti e della nutrizione"},
  {"id":"66","professionId":"4","text":"Igiene degli alimenti e della nutrizione"},
  {"id":"67","professionId":"1","text":"Medicina del lavoro e sicurezza degli ambienti di lavoro"},
  {"id":"68","professionId":"1","text":"Medicina generale (Medici di famiglia)"},
  {"id":"69","professionId":"1","text":"Continuità assistenziale"},
  {"id":"70","professionId":"1","text":"Pediatria (Pediatri di libera scelta)"},
  {"id":"71","professionId":"1","text":"Medicine alternative"},
  {"id":"72","professionId":"5","text":"Igiene degli allevamenti e delle produzioni zootecniche"},
  {"id":"73","professionId":"5","text":"Igiene della produzione, trasformazione, commercializzazione, conservazione e trasporto degli alimenti di origine animale e loro derivati"},
  {"id":"74","professionId":"5","text":"Sanità animale"},
  {"id":"75","professionId":"6","text":"Chirurgia orale"},
  {"id":"76","professionId":"6","text":"Ortodonzia"},
  {"id":"77","professionId":"7","text":"Farmacia ospedaliera"},
  {"id":"78","professionId":"7","text":"Farmacia territoriale"},
  {"id":"79","professionId":"4","text":"Chimica analitica"},
  {"id":"82","professionId":"9","text":"Fisica sanitaria"},
  {"id":"83","professionId":"1","text":"Psicoterapia"},
  {"id":"84","professionId":"10","text":"Psicoterapia"},
  {"id":"85","professionId":"10","text":"Psicologia"},
  {"id":"86","professionId":"11","text":"Assistenti sanitari"},
  {"id":"87","professionId":"12","text":"Dietista"},
  {"id":"88","professionId":"13","text":"Fisioterapista"},
  {"id":"89","professionId":"14","text":"Educatore professionale"},
  {"id":"90","professionId":"15","text":"Igienista dentale"},
  {"id":"91","professionId":"16","text":"Infermiere"},
  {"id":"92","professionId":"17","text":"Infermiere pediatrico"},
  {"id":"93","professionId":"18","text":"Logopedista"},
  {"id":"94","professionId":"19","text":"Ortottista/assistente di oftalmologia"},
  {"id":"95","professionId":"20","text":"Ostetrica/o"},
  {"id":"96","professionId":"21","text":"Podologo"},
  {"id":"97","professionId":"22","text":"Tecnico della riabilitazione psichiatrica"},
  {"id":"98","professionId":"23","text":"Tecnico di fisiopatologia cardiocircolatoria"},
  {"id":"99","professionId":"24","text":"Tecnico sanitario laboratorio biomedico"},
  {"id":"100","professionId":"25","text":"Tecnico sanitario di radiologia medica"},
  {"id":"101","professionId":"26","text":"Tecnico audiometrista"},
  {"id":"102","professionId":"27","text":"Tecnico audioprotesista"},
  {"id":"103","professionId":"28","text":"Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro"},
  {"id":"104","professionId":"29","text":"Tecnico di neurofisiopatologia"},
  {"id":"105","professionId":"30","text":"Tecnico ortopedico"},
  {"id":"106","professionId":"31","text":"Terapista della neuro e psicomotricità dell'età evolutiva"},
  {"id":"107","professionId":"32","text":"Terapista occupazionale"},
  {"id":"108","professionId":"33","text":"Ottico"},
  {"id":"109","professionId":"34","text":"Odontotecnico"},
  {"id":"110","professionId":"1","text":"Ostetricia e ginecologia"},
  {"id":"111","professionId":"1","text":"Medicina legale fiscale"},
  {"id":"112","professionId":"1","text":"Psichiatra"},
  {"id":"114","professionId":"3","text":"Biologia"},
  {"id":"115","professionId":"1","text":"Neurofisiopatologia"},
  {"id":"116","professionId":"1","text":"Direzione medica di presidio ospedaliero"},
  {"id":"117","professionId":"1","text":"Organizzazione dei servizi sanitari di base"},
  {"id":"118","professionId":"1","text":"Privo di specializzazione"},
  {"id":"119","professionId":"6","text":"Odontoiatra"},
  {"id":"120","professionId":"3","text":"Biologo"},
  {"id":"121","professionId":"39","text":"Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare"},
  {"id":"122","professionId":"40","text":"Tecnico della riabilitazione psichiatrica"},
  {"id":"123","professionId":"1","text":"Cure Palliative"},
  {"id":"124","professionId":"1","text":"Epidemiologia"},
  {"id":"125","professionId":"1","text":"Medicina di comunità"}
];

export const provinces = [
  "Altro",
  "AG",
  "AL",
  "AN",
  "AO",
  "AR",
  "AP",
  "AQ",
  "AT",
  "AV",
  "BA",
  "BG",
  "BI",
  "BL",
  "BN",
  "BO",
  "BR",
  "BS",
  "BT",
  "BZ",
  "CA",
  "CB",
  "CE",
  "CH",
  "CI",
  "CL",
  "CN",
  "CO",
  "CR",
  "CS",
  "CT",
  "CZ",
  "EN",
  "FC",
  "FE",
  "FG",
  "FI",
  "FM",
  "FR",
  "GE",
  "GO",
  "GR",
  "IM",
  "IS",
  "KR",
  "LC",
  "LE",
  "LI",
  "LO",
  "LT",
  "LU",
  "MB",
  "MC",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NA",
  "NO",
  "NU",
  "OG",
  "OR",
  "OT",
  "PA",
  "PC",
  "PD",
  "PE",
  "PG",
  "PI",
  "PN",
  "PO",
  "PR",
  "PT",
  "PU",
  "PV",
  "PZ",
  "RA",
  "RC",
  "RE",
  "RG",
  "RI",
  "RM",
  "RN",
  "RO",
  "SA",
  "SI",
  "SO",
  "SP",
  "SR",
  "SS",
  "SU",
  "SV",
  "TA",
  "TE",
  "TN",
  "TO",
  "TP",
  "TR",
  "TS",
  "TV",
  "UD",
  "VA",
  "VB",
  "VC",
  "VE",
  "VI",
  "VR",
  "VS",
  "VT",
  "VV"
];

export const getProvince = () => {
  return provinces.map(x => ({value:x, label:x}))
}

export const getTitoliUtente = () => {
  return titles.map(x => ({value:x.text, label:x.text}))
}

export const getSpecializzazioniUtente = (professionId:string) => {
  if(professionId==undefined)
    return [];

  let specs = specializations. map(x => ({value:x.text, label:x.text, professionId:x.professionId}));
  if(professionId!=undefined){
    return filter(specs, {"professionId":professionId})
  }
  return specs;
}
export const getProfessioniUtente = () => {
  return professions. map(x => ({value:x.text, label:x.text, linkedId:x.id}));
}
