import React, {Component} from 'react';
import {Bar, defaults} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

defaults.global.defaultFontSize = 30;

const options = {
  responsive: true,
  maintainAspectRatio:true,
  legend: {
    display:false,
  },
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        max: 20, // modificato nel costruttore
        min: 0,
        stepSize: 2,
        font: {
          size: 18
        },
      }
    }],
    xAxes:[{
      font: {
        size: 22
      },
    }]
  },
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      textAlign:'center',
      formatter: (x) => x!=0 ? x : "",
      anchor: "end",
      offset: -50,
      align: "start",
      font: {
        weight: 'bold',
        size:30
      },
    },
    title: {
      display: false,
      text: '',
    }
  },
};

class Histogram extends Component {

  /**
   * COMPONENT LIFECYCLE METHODS
   */
  constructor(props){
    super(props);
    this.state = {

    }

    options.scales.yAxes[0].ticks.max=Math.max(...this.props.group1_3Data, ...this.props.group4_6Data, ...this.props.group7_9Data)+2;
  }

  renderRANDResults = () => {

    const {numeroVotanti, media, mediana, dispersione, accordo, appropriatezza} = this.props.randResults

    const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const data = {
      labels,
      datasets: [
        {
          label: '1-3',
          data:this.props.group1_3Data,
          backgroundColor: 'rgba(234, 51, 35, 1)',
        },
        {
          label: '4-6',
          data:this.props.group4_6Data,
          backgroundColor: 'rgba(245, 195, 66, 1)',
        },
        {
          label: '7-9',
          data:this.props.group7_9Data,
          backgroundColor: 'rgba(78, 173, 91, 1)',
        }
      ],
    };


    return (
      <React.Fragment>
        <div style={{width: "110vh", alignSelf: "center"}}>
          <div style={{display:"flex", marginTop:"25px", marginBottom:"5px"}}>
            <Bar options={options} data={data} width={100} plugins={[ChartDataLabels]}
                 height={44}/>
            <div className={`randButton ${appropriatezza.toLowerCase()}`} style={{alignSelf:"flex-start", marginTop:"-25px", marginLeft:"-10px"}}>{appropriatezza}</div>
          </div>
        </div>
        <div style={{alignSelf: "center", display: "flex", justifyContent:"center"}}>
          <div style={{display: "inline-flex"}}>
            {this.getDivReport("Votanti", numeroVotanti, 'rgba(191,189,60)', 'rgba(239,238, 206)')}
            {this.getDivReport("Media", media,'rgba(196,67,126)', 'rgba(240,208, 223)')}
            {this.getDivReport("Mediana", mediana, 'rgba(139,107,167)', 'rgba(226,218, 233)')}
            {this.getDivReport("Dispersione", dispersione, 'rgba(76,93,158)', 'rgba(210,214, 231)')}
            {this.getDivReport("Accordo", !!accordo ? 'SI': 'NO', 'rgba(78,173,91)', 'rgba(211,234, 214)')}
          </div>
        </div>


      </React.Fragment>

    )
  }


  getDivReport(title, value, backgroundColor, backgroundColorLight) {
    return <div style={{fontSize: "30px"}}>
      <div style={{backgroundColor: backgroundColor, fontWeight: "bold", border: "2px solid white", color:"white"}}>
        <span style={{padding: "20px"}}>{title}</span>
      </div>
      <div style={{backgroundColor:backgroundColorLight, textAlign: "center", fontWeight: "initial", border: "2px solid white"}}>
        <span>{value}</span>
      </div>
    </div>;
  }

  /**
   * RENDER
   */

  render() {
    return this.renderRANDResults()
  }
}

export default Histogram;
