import React from 'react'
import {BoxedContainer} from "./Helpers";
import {Col, Container, Row} from 'reactstrap';


export const TimeResultRow = ({
                   text, time, ...restProps
                 }) => (
  <BoxedContainer className="ranking-row" {...restProps}>


    <Container fluid>
      <Row style={{marginTop: "2px", justifyContent:"space-between"}}>
        <Col md={4}></Col>
        <Col md={4}>
          <div className="d-flex flex-grow-1 diagnosis-card shadow-custom h-100"
               style={{justifyContent: "space-between",  padding: "10px 15px"}}>
            <span>{text}</span><span className="verticalAlignMiddle">{time}</span>
          </div>
        </Col>
        <Col md={4}></Col>
      </Row>
    </Container>
  </BoxedContainer>
);
