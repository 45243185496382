import React, {Component} from 'react';
import {Card, CardBody, Col, Container, Row,} from 'reactstrap';
import ComponentStatus from "../model/ComponentStatus"
import {strings} from "../resources/strings";
import {find, get} from 'lodash'
import CongressesService from "../remote/services/CongressesService";
import {AppHeader, AppNavbarBrand} from "@coreui/react";
import parentLogo from '../assets/img/parent_logo.png';
import horizontalLogo from '../assets/img/brand/horizontal_logo.png';
import ModalError from "./utility/ModalError";
import {extractMessageFromError} from "../utility/common";
import ClinicCasesService from "../remote/services/ClinicCasesService";


const REFRESH_RATE_MS = 5000;


class RisposteCasoClinico extends Component {

  /**
   * COMPONENT LIFECYCLE METHODS
   */

  constructor(props){
    super(props);
    this.state = {
      showModal:false,
      componentStatus:ComponentStatus.LOADING,
      errorMessages:[],
      clinicCaseStats: undefined,
      showLoader:false
    }
    this.congressesService = new CongressesService();
    this.clinicCaseService = new ClinicCasesService();
  }

  getCongressId = () => {
    return this.props.match.params.congressId;
  }

  getSessionId = () => {
    return this.props.match.params.sessionId;
  }

  getClinicCaseId = () => {
    return this.props.match.params.clinicCaseId;
  }


  componentDidMount() {

    let reqs = [
      this.congressesService.getCongressConfigById(this.getCongressId()),
    ]

    Promise.all(reqs)
      .then(res => {
        let graphicCongressConfiguration = get(res[0].data, 'graphicConfiguration', undefined);


        let logoToShow = get(res[0].data, 'graphicConfiguration.logoDashboard', undefined);
        if(logoToShow==undefined || logoToShow.trim()=="")
          this.setState({
            logoToShow:horizontalLogo,
          })
        else
          this.setState({
            logoToShow
          })

        this.getQuestionsAnswersCount(true)
      })
      .catch(e =>{
        console.error(e);
      })

  }

  componentWillUnmount() {
    try {
      clearTimeout(this.refreshDataInterval);
    } catch (e) {
    }
  }

  showLoader = (show) => {
    this.setState({showLoader:show})
  }

  getAnswerCount = () => {
    let answer = find(this.state.questionAnswerCount, {'stato': "SOTTOMESSA"})
    if (answer) {
      return "Risposte pervenute: "+answer.questionAnswersCount
    }
  }

  /**
   * REMOTE REQUESTS
   */
  getQuestionsAnswersCount = (isFirst=false) => {
    if(isFirst===true){
      this.showLoader(true)
    }

    try{
      clearTimeout(this.refreshDataInterval)
    }catch (e) {
      console.error(e)
    }

    this.congressesService.getQuestionsAnswerCount(this.getCongressId(), this.getClinicCaseId())
      .then(res => {
        this.setState({
          questionAnswerCount:res.data
        }, () => {
          this.refreshDataInterval = setTimeout(this.getQuestionsAnswersCount, REFRESH_RATE_MS)
        })
      })
      .catch((err) => {
        console.error(err);
        if(isFirst===true){
          const messageToShow = extractMessageFromError(err,  strings.casoClinico.errors.loading);
          this.setState({errorMessages: [...this.state.errorMessages, messageToShow]})
        }
      })
      .finally(()=>{
        if(isFirst===true){
          this.showLoader(false)
        }
      })
  }


  /**
   * RENDER
   */

  render() {

    return (
      <div className="animated fadeIn d-flex flex-column" style={{minHeight: "100vh"}}>
        <div className={"customNavbar"} style={{display:"flex", justifyContent:"center", height:"30px"}}>
          <img src={parentLogo} style={{width: "auto", height: "15px", alt: strings.platformName, marginBottom:"auto", marginTop:"auto"}} alt={""}/>
        </div>
        <Container fluid style={{paddingTop: "0px"}} className="flex-grow-1 bg-white">
        <Row>
          <Col className="d-flex justify-content-center" style={{paddingTop: "0px", paddingBottom: "20px"}}>
            {this.state.logoToShow &&
              <img src={this.state.logoToShow} alt="logo" style={{height: "120px"}}/>
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <p style={{display: 'flex', justifyContent: 'center'}}>
                  <span style={{fontSize: '6em'}}>{this.getAnswerCount()}</span>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </Container>

        {/* Modale per le visualizzazione degli errori durante le richieste al backend*/}
        <ModalError isOpen={this.state.errorMessages && this.state.errorMessages.length>0}
               onCancel={()=>{this.setState({errorMessages:[]})}}
               message={this.state.errorMessages}
               modalBodyStyle={{overflowY: "unset"}}
        />


       {/* <div style={{ 'visibility': this.state.showLoader==false? 'hidden' : 'visible' }}>
          <Loader zIndex={1060} fullPage={true} />
        </div>*/}


      </div>
    );
  }
}

export default RisposteCasoClinico;
