import React, {Component} from 'react';
import {Col, Container, Row,} from 'reactstrap';
import ComponentStatus from "../model/ComponentStatus"
import {filter, orderBy} from 'lodash'
import ModalError from "./utility/ModalError";
import {extractMessageFromError} from "../utility/common";
import SessionsService from "../remote/services/SessionsService";
import CongressesService from "../remote/services/CongressesService";

const REFRESH_RATE_MS = 10000;
const SPEED_MARQUEE = 0.39;

const defaultConfigurableStyle= {
  "backgroundColor": "#00FF00",
  "containerTop":"unset",
  "containerBottom":"0",
  "rowBackgroundColor":"#FFFFFF",
  "rowColor":"white",
  "rowFontsize":"2.50em",
  "containerMarqueeElemPaddingTop":"unset",
  "containerMarqueeElemPaddingBottom":"5px",
}

class MessaggiPubblici extends Component {

  /**
   * COMPONENT LIFECYCLE METHODS
   */
  constructor(props){
    super(props);
    this.state = {
      componentStatus:ComponentStatus.LOADING,
      errorMessages:[],
      show:true
    }
    this.sessionService = new SessionsService();
    this.congressService = new CongressesService();
  }

  getCongressId = () => {
    return this.props.match.params.congressId;
  }

  getSessionId = () => {
    return this.props.match.params.sessionId;
  }

  componentDidMount() {
    this.getData()
  }

  componentWillUnmount() {
    try {
      clearInterval(this.refreshDataInterval);
    } catch (e) {
    }
  }

  extractMessagesTimeAndDuration = (messages) => {
    const stringToShow = messages.map(x => (x.username + ": "+x.message)).join(" - ");
    //const stringToShow = "LUCA OLIVOTTI: se la riduzione dello scompenso cardiaco è l'effetto più importante della terapia antiipertensiva, e vista l'efficacia di entresto nel ridurre la pressione, cosa ci impedisce di usare entresto come antiipertensivo (a parte il prezzo, comunque inferiore alla denervazione renale)? - Luciano Carbone: Quanto è importante seguire il paziente iperteso con un Holter pressorio da effettuarsi due volte l’anno per capire l’aderenza alla terapia farmacologica? - ﻿ILARIA BATTISTONI: Quali strategie terapeutiche ed interventistiche possiamo proporre ad un paziente con ipertensione arteriosa resistente in dialisi? Grazie - MARIO CHIATTO: Effetti collaterali e complicanze della terapia con GLP1"
    //const timeTaken =   (stringToShow.length || 0) * SPEED_MARQUEE;+
    console.log(stringToShow.length)
    const timeTaken =   (stringToShow.length || 0) * SPEED_MARQUEE;//stringToShow.length*63/238; //(stringToShow.length || 0) * SPEED_MARQUEE;
    const defaultDuration ='marquee2 ' + timeTaken + 's linear'

    return{
      stringToShow,
      defaultDuration
    }
  }

  setMarqueeEventListener = () => {
    let elem = document.getElementById("marqueeElem")
    if(elem!=undefined){
      elem.addEventListener("animationend", () => {
        this.resetDuration()
      }, false)
    }
  }

  resetDuration = () => {
    this.setState({
      defaultDuration: 0
    }, () => {
      const {stringToShow, defaultDuration} = this.extractMessagesTimeAndDuration(this.updatedMessages|| this.state.messages)

      this.setState({
        defaultDuration,
        messages:this.state.messages,
        stringToShow,
      }, this.setMarqueeEventListener)
    })
  }

  filterMessagesAndOrder(messages) {
    return orderBy(filter(messages, x => x.status === "PUBBLICO"), 'timestamp')
  }

  /**
   * REMOTE REQUESTS
   */

  _getData = () => {
    let promise = this.sessionService.getSessionMessages(this.getCongressId(), this.getSessionId())

    return promise
      .then(response => {
        const messages = this.filterMessagesAndOrder(response.data);
        return {
          messages:messages
        };
      }).catch(err =>{
        console.error(err)
        return Promise.reject(new Error("Risposta vuota"));
      })
  }

  updateData = () => {
    this._getData()
      .then(state => {
        this.updatedMessages = state.messages;
      })
  }

  getData = async () => {
    try{
      let res = await this.congressService.getCongressById(this.getCongressId());
      this.setState({
        twitterStyleConfiguration:res.data.configuration.twitterConfiguration
      })
    }catch (error) {
      const message = extractMessageFromError(error, "Si è verificato un errore. Si prega di chiudere e riaprire la pagina");
      this.setState({
        errorMessages: [...this.state.errorMessages, message],
        componentStatus: ComponentStatus.ERROR
      })
    }

    this._getData()
      .then(state => {
        const {stringToShow, defaultDuration} = this.extractMessagesTimeAndDuration(state.messages)

        this.setState({
          messages: state.messages,
          stringToShow,
          componentStatus: ComponentStatus.LOADED,
          show:true,
          defaultDuration
        }, this.setMarqueeEventListener)
      })
      .then(() => {
        this.refreshDataInterval = setInterval(() => this.updateData(), REFRESH_RATE_MS);
      })
      .catch((error) => {
        console.error(error)
        const message = extractMessageFromError(error, "Si è verificato un errore. Si prega di chiudere e riaprire la pagina");
        this.setState({
          errorMessages: [...this.state.errorMessages, message],
          componentStatus: ComponentStatus.ERROR
        })
      })
      .finally(() => {
      })
  }


  /**
   * RENDER
   */
  render() {
    const messages = this.state.messages
    const stringToShow = this.state.stringToShow
    const {twitterStyleConfiguration} = this.state

    if(twitterStyleConfiguration === undefined){
      return <div></div>
    }

    return (
      <div id="divContainer" className="animated fadeIn d-flex flex-column" style={{minHeight: "100vh", backgroundColor:twitterStyleConfiguration?.backgroundColor || "unset"}}>

        <Container id="containerRow" fluid style={{position:"absolute", top: twitterStyleConfiguration?.containerTop || "unset", bottom: twitterStyleConfiguration?.containerBottom || "unset"}} className="flex-grow-1">
          <Row style={{backgroundColor:twitterStyleConfiguration?.rowBackgroundColor || "unset", color:twitterStyleConfiguration?.rowColor || "unset", fontSize:twitterStyleConfiguration?.rowFontsize || "unset"}}>
            {this.state.defaultDuration!=0 &&
              <Col>
                <div id="containerMarqueeElem" className="wrapper" style={{"paddingTop":twitterStyleConfiguration?.containerMarqueeElemPaddingTop || "unset", "paddingBottom":twitterStyleConfiguration?.containerMarqueeElemPaddingBottom || "unset"}} >
                  <div id="marqueeElem" className="marquee2"
                       style={{fontSize:"1.25em", marginLeft:"1px", marginRight:"1px",
                         animation:  this.state.defaultDuration,
                         "-webkit-animation": this.state.defaultDuration
                       }}>{stringToShow}</div>
                </div>
              </Col>
            }
          </Row>
        </Container>

    {/* Modale per le visualizzazione degli errori durante le richieste al backend*/}
    <ModalError isOpen={this.state.errorMessages && this.state.errorMessages.length>0}
                onCancel={()=>{this.setState({errorMessages:[]})}}
                message={this.state.errorMessages}
                modalBodyStyle={{overflowY: "unset"}}
    />

  </div>
  );
  }
}

export default MessaggiPubblici;
